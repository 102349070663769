import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Logo from 'common/components/UIElements/Logo';
import Container from 'common/components/UI/Container';
import FooterArea, {
  Left,
  Menu,
  CopyText,
} from './footer.style';

const Footer = () => {
  const Data = useStaticQuery(graphql`
    query {
      appMinimalJson {
        FooterData {
          menu {
            link
            label
          }
          logo {
            publicURL
          }
          social {
            link
            icon {
              publicURL
            }
          }
        }
      }
    }
  `);
  const { menu, logo } = Data.appMinimalJson.FooterData;
  const date = new Date();
  const year = date.getFullYear();

  return (
    <FooterArea>
      <Container className="Container">
        <Left>
          <Logo
            className="logo"
            href="/"
            logoSrc={logo.publicURL}
            title="Empresa de Demoliciones Bogota"
          />
          <CopyText>
            Copyright © {year}
            <Link to="/">Empresa de Demoliciones Bogota</Link>
          </CopyText>
        </Left>
        <Menu>
          {menu.map(({ link, label }, index) => (
            <Link to={link} key={`footer-link-key-${index}`}>
              {label}
            </Link>
          ))}
        </Menu>

      </Container>
    </FooterArea>
  );
};

export default Footer;
